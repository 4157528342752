<script setup>
import { ref } from 'vue';
import { BButton, BForm, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BModal } from 'bootstrap-vue-next';
import { REWARD_REASONS } from '~/common/Constants';

const blankForm = {
  usernames: [],
  note: '',
  credits: null,
  reason: '',
};
const busy = ref(false);
const form = ref({ ...blankForm });
const props = defineProps({
  userId: {
    type: String,
    required: false,
    default: null,
  },
});
const model = defineModel({ type: Boolean });
const emit = defineEmits(['rewardIssued']);

const reasonOptions = Object.entries(REWARD_REASONS).map(
  ([value, text]) => ({ value, text }),
);

const issueReward = async (event) => {
  busy.value = true;
  event.preventDefault();

  const formData = { ...form.value };

  let endpoint = '/api/v1/rewards/bulk_issue';
  if (props.userId != null) {
    endpoint = '/api/v1/rewards/issue';
    formData.user_id = props.userId;
  } else {
    formData.usernames = formData.usernames.split('\n').map(x => x.trim()).filter(x => x);
  }

  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  }).then(() => {
    busy.value = false;
    emit('rewardIssued', form.value);
    form.value = { ...blankForm };
    model.value = false;
  });
};
</script>

<template>
  <BModal
    v-model="model"
    title="Issue reward"
    hide-footer
  >
    <BForm @submit="issueReward">
      <BFormGroup
        v-if="userId == null"
        label="Users (one per line):"
        class="mb-3"
      >
        <BFormTextarea
          v-model="form.usernames"
          required
        />
      </BFormGroup>
      <BFormGroup
        label="Credits:"
        class="mb-3"
      >
        <BFormInput
          v-model="form.credits"
          type="number"
          required
        />
      </BFormGroup>
      <BFormGroup
        label="Reason (not be added to the contributor issue):"
        class="mb-3"
      >
        <BFormSelect
          v-model="form.reason"
          :options="reasonOptions"
          required
        />
      </BFormGroup>
      <BFormGroup
        label="Note (added to the contributor issue, use markdown):"
        class="mb-3"
      >
        <BFormTextarea
          v-model="form.note"
        />
      </BFormGroup>
      <BButton
        type="submit"
        class="float-end"
        variant="primary"
        :loading="busy"
        :disabled="busy"
      >
        Issue reward
      </BButton>
    </BForm>
  </BModal>
</template>
