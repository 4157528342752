<script setup>
import { BContainer } from 'bootstrap-vue-next';
import NavigationMenu from '~/common/components/NavigationMenu.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const showNav = computed(() => {
  return route?.name !== 'manage-merge-request';
});
</script>

<template>
  <NavigationMenu v-if="showNav" />
  <BContainer class="pt-2">
    <router-view />
    <hr>
    <p>
      <a
        href="https://gitlab.com/gitlab-org/developer-relations/contributor-success/contributors-gitlab-com"
        target="_blank"
      >View source</a>
      ||
      <!-- OneTrust Cookies Settings button start -->
      <a
        class="ot-sdk-show-settings"
        href="#"
      >
        Cookie settings
      </a>
      <!-- OneTrust Cookies Settings button end -->
    </p>
  </BContainer>
</template>
