<script setup>
import { ref } from 'vue';
import { buildUrl } from '~/common/UrlUtils';
import { REWARD_REASONS } from '~/common/Constants';
import { dateFormatter } from '~/common/FieldFormats';
import { BButton, BLink, BOverlay, BPagination, BTable } from 'bootstrap-vue-next';
import IssueRewardModal from '~/common/components/IssueRewardModal.vue';

const rewards = ref([]);
const pageInfo = ref({
  currentPage: 1,
  perPage: 50,
  totalRows: 0,
});
const loadingPage = ref(true);
const showIssueRewardModal = ref(false);

const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'reason',
    label: 'Reason',
    formatter: value => REWARD_REASONS[value],
  },
  {
    key: 'gift_code',
    label: 'Gift code',
  },
  {
    key: 'credits',
    label: 'Credits',
  },
  {
    key: 'awarded_by',
    label: 'Issued by',
  },
  {
    key: 'created_at',
    label: 'Issued date',
    formatter: dateFormatter,
  },
];

const fetchData = async (page) => {
  loadingPage.value = true;
  const url = buildUrl('/api/v1/rewards', { query: { page } });
  const response = await fetch(url);
  const j = await response.json();
  rewards.value = j.records;
  pageInfo.value = {
    currentPage: j.metadata.current_page,
    perPage: j.metadata.per_page,
    totalRows: j.metadata.total_count,
  };
  loadingPage.value = false;
};

fetchData(1);
</script>

<template>
  <BOverlay
    :show="loadingPage"
    no-wrap
    fixed
  >
    <BButton
      variant="outline-primary"
      size="sm"
      @click="showIssueRewardModal = true"
    >
      Issue rewards
    </BButton>
    <BTable
      :fields="fields"
      :items="rewards"
      show-empty
    >
      <template #cell(username)="cell">
        <BLink
          :to="{
            name: 'user',
            params: { userId: cell.item.user_id },
          }"
        >
          {{ cell.value }}
        </BLink>
      </template>
      <template #cell(gift_code)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.issue_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
      <template #cell(awarded_by)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.awarded_by_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
    </BTable>
    <BPagination
      v-model="pageInfo.currentPage"
      :total-rows="pageInfo.totalRows"
      :per-page="pageInfo.perPage"
      @update:model-value="fetchData($event)"
    />
  </BOverlay>

  <IssueRewardModal
    v-model="showIssueRewardModal"
  />
</template>
