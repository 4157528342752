<script setup>
import { inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { BButton, BLink } from 'bootstrap-vue-next';
import { buildUrl } from '~/common/UrlUtils';
import FilterCard from '~/common/components/FilterCard.vue';
import { dateFormatter, numberProps } from '~/common/FieldFormats';
import ContributorLevelBadge from '../common/components/ContributorLevelBadge.vue';
import TableWithBusyState from '../common/components/TableWithBusyState.vue';
import AddPointsModal from './user-page/AddPointsModal.vue';
import IssueRewardModal from '~/common/components/IssueRewardModal.vue';
import { BONUS_POINT_ACTIVITY_TYPES, REWARD_REASONS } from '~/common/Constants';

const vueData = inject('vueData');
const admin = vueData.userAdmin;

const data = ref({});
const busy = ref(true);
const filterData = ref({
  fromDate: '',
  toDate: '',
});
const showAddPointsModal = ref(false);
const showIssueRewardModal = ref(false);

const route = useRoute();

const rewardFields = [
  {
    key: 'reason',
    label: 'Reason',
    formatter: value => REWARD_REASONS[value],
  },
  {
    key: 'gift_code',
    label: 'Gift code',
  },
  {
    key: 'credits',
    label: 'Credits',
  },
  {
    key: 'awarded_by',
    label: 'Issued by',
  },
  {
    key: 'created_at',
    label: 'Issued date',
    formatter: dateFormatter,
  },
];

const bonusPointsFields = [
  {
    key: 'activity_type',
    label: 'Activity type',
    formatter: value => BONUS_POINT_ACTIVITY_TYPES[value],
  },
  {
    key: 'reason',
    label: 'Reason',
  },
  {
    key: 'created_at',
    label: 'Awarded date',
    formatter: dateFormatter,
  },
  {
    key: 'points',
    label: 'Points',
    ...numberProps,
  },
];

const mergedMergeRequestFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
    formatter: dateFormatter,
  },
  {
    key: 'merged_date',
    label: 'Merge date',
    formatter: dateFormatter,
  },
  {
    key: 'has_linked_issue',
    label: 'Linked issue',
  },
];

const openedMergeRequestFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
    formatter: dateFormatter,
  },
  {
    key: 'state_id',
    label: 'State',
  },
  {
    key: 'has_linked_issue',
    label: 'Linked issue',
  },
];

const commitFields = [
  {
    key: 'merge_request_title',
    label: 'Merge request title',
  },
  {
    key: 'sha',
    label: 'SHA',
  },
  {
    key: 'committed_date',
    label: 'Commit date',
    formatter: dateFormatter,
  },
  {
    key: 'merged_date',
    label: 'Merge date',
    formatter: dateFormatter,
  },
];

const issueFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
    formatter: dateFormatter,
  },
  {
    key: 'state_id',
    label: 'State',
  },
];

const noteFields = [
  {
    key: 'noteable_title',
    label: 'Noteable title',
  },
  {
    key: 'added_date',
    label: 'Added date',
    formatter: dateFormatter,
  },
];

const discordMessageFields = [
  {
    key: 'added_date',
    label: 'Added date',
    formatter: dateFormatter,
  },
  {
    key: 'reply',
    label: 'Reply',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
];

const forumPostFields = [
  {
    key: 'topic',
    label: 'Topic',
  },
  {
    key: 'reply',
    label: 'Reply',
  },
  {
    key: 'added_date',
    label: 'Added date',
    formatter: dateFormatter,
  },
];

const filter = () => {
  busy.value = true;
  fetchData();
};

const { userId } = route.params;
const fetchData = async () => {
  const query = { from_date: filterData.value.fromDate, to_date: filterData.value.toDate };
  const url = buildUrl(`/api/v1/users/${userId}`, { query });

  const response = await fetch(url);
  data.value = await response.json();
  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    @filter="filter"
  />

  <h1 class="d-flex align-items-center justify-content-between">
    <span>
      <ContributorLevelBadge
        :contributor-level="data.contributor_level"
      />
      <a
        :href="`https://gitlab.com/${data.username}`"
        target="_blank"
      >
        {{ data.username }}
      </a>
    </span>
    <BButton
      v-if="admin"
      variant="outline-primary"
      size="sm"
      :to="{
        name: 'user-edit',
        params: { userId: data.id },
      }"
    >
      Edit user
    </BButton>
  </h1>

  <template v-if="admin">
    <h2 class="d-flex align-items-center justify-content-between">
      <span>Rewards ({{ data.rewards?.length }})</span>
      <BButton
        variant="outline-primary"
        size="sm"
        @click="showIssueRewardModal = true"
      >
        Issue reward
      </BButton>
    </h2>
    <TableWithBusyState
      :fields="rewardFields"
      :items="data.rewards"
      :busy="busy"
      show-empty
    >
      <template #cell(gift_code)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.issue_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
      <template #cell(awarded_by)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.awarded_by_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
    </TableWithBusyState>
  </template>

  <h2 class="d-flex align-items-center justify-content-between">
    <span>Bonus Points ({{ data.bonus_points?.length }})</span>
    <BButton
      v-if="admin"
      variant="outline-primary"
      size="sm"
      @click="showAddPointsModal = true"
    >
      Add points
    </BButton>
  </h2>
  <TableWithBusyState
    :fields="bonusPointsFields"
    :items="data.bonus_points"
    :busy="busy"
    show-empty
  />

  <h2>Merged Merge Requests ({{ data.merged_merge_requests?.length }})</h2>
  <TableWithBusyState
    :fields="mergedMergeRequestFields"
    :items="data.merged_merge_requests"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
    <template #cell(has_linked_issue)="{ value }">
      <span v-if="value">✓</span>
    </template>
  </TableWithBusyState>

  <h2>Opened Merge Requests ({{ data.opened_merge_requests?.length }})</h2>
  <TableWithBusyState
    :fields="openedMergeRequestFields"
    :items="data.opened_merge_requests"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
    <template #cell(has_linked_issue)="{ value }">
      <span v-if="value">✓</span>
    </template>
  </TableWithBusyState>

  <h2>Commits ({{ data.merged_commits?.length }})</h2>
  <TableWithBusyState
    :fields="commitFields"
    :items="data.merged_commits"
    :busy="busy"
    show-empty
  >
    <template #cell(merge_request_title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.merge_request_web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </TableWithBusyState>

  <h2>Opened Issues ({{ data.opened_issues?.length }})</h2>
  <TableWithBusyState
    :fields="issueFields"
    :items="data.opened_issues"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </TableWithBusyState>

  <h2>Added Notes ({{ data.added_notes?.length }})</h2>
  <TableWithBusyState
    :fields="noteFields"
    :items="data.added_notes"
    :busy="busy"
    show-empty
  >
    <template #cell(noteable_title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </TableWithBusyState>

  <h2>Discord Messages ({{ data.discord_messages?.length }})</h2>
  <TableWithBusyState
    :fields="discordMessageFields"
    :items="data.discord_messages"
    :busy="busy"
    show-empty
  >
    <template #cell(added_date)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
    <template #cell(reply)="{ value }">
      <span v-if="value">✓</span>
    </template>
  </TableWithBusyState>

  <h2>Forum Posts ({{ data.forum_posts?.length }})</h2>
  <TableWithBusyState
    :fields="forumPostFields"
    :items="data.forum_posts"
    :busy="busy"
    show-empty
  >
    <template #cell(topic)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
    <template #cell(reply)="{ value }">
      <span v-if="value">✓</span>
    </template>
  </TableWithBusyState>

  <AddPointsModal
    v-model="showAddPointsModal"
    :user-id="userId"
    @points-added="fetchData"
  />

  <IssueRewardModal
    v-model="showIssueRewardModal"
    :user-id="userId"
    @reward-issued="fetchData"
  />
</template>
